<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-07 09:43:22
 * @LastEditors: ------
 * @LastEditTime: 2021-08-17 15:19:12
-->
// 服务合同管理详情
<template>
  <div class="big_box">
    <TitleNav class=" ">
      <template #one>
        <span @click="go" class="cursor">
          首页 >
        </span>
      </template>
      <template #two>
        <span @click="go" class="cursor">
          服务合同管理
        </span>
      </template>
      <template v-slot:slotCon>
        <div class="smallBox bg_white_color">
          <div class="box_left">
            <div class="left_top">
              <img src="../image/serve_type.png" alt="" />
              <span class="base_333_color">客户信息</span>
            </div>
            <div class="left_bot">
              <div>
                <span>合同类型：</span>
                <a-radio-group v-model="value" @change="onChange">
                  <a-radio :value="1">
                    新建合同
                  </a-radio>
                  <a-radio :value="2">
                    续签合同
                  </a-radio>
                </a-radio-group>
              </div>
              <div class="text_box">
                <div>用户姓名：</div>
                <div class="name">
                  <a-select
                    show-search
                    :value="valueFind"
                    placeholder="请输入客户姓名或手机号"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handleSearch"
                    @change="handleChange"
                  >
                    <a-select-option v-for="(d, index) in data" :key="index">
                      {{ d.text }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="text_box">
                <div>用户手机号：</div>
                <input
                  placeholder="请输入用户手机号"
                  type="text"
                  class="userName"
                  v-model="number"
                  ref="Number"
                />
              </div>
              <div class="text_box">
                <div>用户身份证：</div>
                <input
                  placeholder="请输入用户身份证号"
                  class="userName"
                  v-model="id"
                  ref="id"
                />
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="box_right">
            <div class="left_top">
              <img src="../image/serve_type.png" alt="" />
              <span class="base_333_color">服务对象信息</span>
            </div>
            <div class="left_bot">
              <div class="text_box">
                <div>服务对象姓名：</div>
                <input
                  placeholder="请输入服务对象姓名"
                  class="userName"
                  v-model="ServeName"
                  ref="ServeName"
                  v-if="ServeName1.length == 0"
                />
                <div v-else>
                  <a-select
                    :default-value="ServeName1[0].name"
                    @change="handleChange2"
                  >
                    <a-select-option
                      :value="item.name"
                      v-for="(item, index) of ServeName1"
                      :key="index"
                    >
                      {{ item.kinship }} : {{ item.name }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="text_box">
                <div>服务对象所在城市：</div>
                <a-cascader
                  size="large"
                  :options="options"
                  @change="onChange1"
                  placeholder="省/市/区"
                ></a-cascader>
                <!-- <a-select default-value="上海市" @change="handleChange1">
                  <a-select-option value="3101">
                    上海市
                  </a-select-option>
                </a-select> -->
              </div>
              <div class="text_box">
                <div>服务对象居住地址：</div>
                <input
                  placeholder="请输入服务对象居住地址"
                  class="userName"
                  v-model="ServeAddress"
                  ref="ServeAddress"
                />
              </div>
            </div>
          </div>
          <button class="nextPage cursor base_white_color" @click="nextPage">
            下一步，选择服务类型
          </button>
        </div>
      </template>
    </TitleNav>
    <ebutton class="right_btn ">
      <template #one>
        <button @click="goContract" class="btn1 cursor base_white_color">
          创建服务合同
        </button>
      </template>
      <template #two>
        <button @click="createUser" class="btn2 cursor base_white_color">
          创建客户信息
        </button>
      </template>
      <!-- <template v-slot:three> 这是插入到three插槽的内容 </template> -->
    </ebutton>
  </div>
</template>

<script>
import TitleNav from "../components/TitleNav"; // 上部导航 插槽
import ebutton from "../components/RightList"; // 右侧导航 插槽
import { getNurseFind, getServeName } from "../api/api"; // 查询用户姓名 手机号
// import { getItem } from "../utils/storage";
import city from "../assets/pca-code.json";

export default {
  name: "serveDetail",
  data() {
    return {
      value: "",
      name: "",
      number: "",
      id: "",
      ServeName: "",
      ServeName1: "",
      ServeCity: "",
      ServeAddress: "",
      ServeType: "",
      data: [],
      valueFind: undefined,
      setContractInfo: {
        ServeType: "",
        name: "",
        mobile: "",
        idCard: "",
        ServeName: "",
        serviceCity: "",
        detailAddress: "",
        accountId: "",
        flag: "0",
        accountFamilyMemberId: "",
        accountFamilyMobile: "",
        isRenew: "",
      },
      isClick: true,
      options: null,
      cityValue: "",
    };
  },
  components: { TitleNav, ebutton },
  created() {
    this.options = city;
  },
  methods: {
    handleChange2(value) {
      console.log(`selected ${value}`);
      this.isClick = !this.isClick;
      this.setContractInfo.ServeName = value;
    },
    handleSearch(valueFind) {
      fetch(valueFind, (data) => (this.data = data));
    },
    async handleChange(valueFind) {
      if (this.data[valueFind].idCard == null) {
        this.id = "";
      } else {
        this.id = this.data[valueFind].idCard;
      }
      this.number = this.data[valueFind].phone;
      this.name = this.data[valueFind].text;

      console.log(this.name, valueFind, "====================");
      // 获取服务对象姓名
      let res = await getServeName({ id: this.data[valueFind].value });
      this.ServeName1 = res.data.result;
      console.log(this.ServeName1[0].accountId);
      this.setContractInfo.accountId = this.ServeName1[0].accountId;
      this.setContractInfo.accountFamilyMobile = this.ServeName1[0].mobile;
      this.setContractInfo.accountFamilyMemberId = this.ServeName1[0].id;
      console.log(this.ServeName1, "===========-=-=-=");
    },

    onChange(e) {
      console.log("radio checked", e.target.value);
      this.ServeType = e.target.value;
      //   暂时新建N  续签Y
      if (e.target.value == 1) {
        this.setContractInfo.isRenew = "N";
      } else if (e.target.value == 2) {
        this.setContractInfo.isRenew = "Y";
      }
    },
    onChange1(value, selectedOptions) {
      const select = selectedOptions.map((item) => item.label);
      // this.value = select
      this.ServeCity = value[1];
      this.setContractInfo.serviceCity = this.ServeCity;
      //服务对象所在城市 省市区
      let ccid = "";
      for (const value in select) {
        ccid = ccid + select[value];
      }
      this.cityValue = ccid;
      this.$forceUpdate();
    },
    go() {
      this.$router.push("/home");
    },
    goContract() {
      this.$router.push("/serveManage");
    },
    createUser() {
      this.$router.push("/infoOne");
    },
    nextPage() {
      let that = this;
      if (that.ServeType == "") {
        that.$message.warning("请选择合同类型!");
        return false;
      }
      if (that.name == "") {
        //校验姓名
        that.$message.warning("请填写用户姓名!");
        return false;
      } else if (that.name != "") {
        // console.log(that.name);
      }
      if (that.number == "") {
        //校验手机号
        that.$refs.Number.placeholder = "不能为空";
        that.$refs.Number.style.border = "1px solid var(--themeColor)";
        return false;
      } else if (that.number != "") {
        // let phone = /^[1][3,4,5,7,8][0-9]{9}$/;
        // if (phone.test(that.number) == true) {
        that.$refs.Number.style.border = "";
        // } else {
        //   that.number = "";
        //   that.$refs.Number.placeholder = "手机号错误";
        //   that.$refs.Number.style.border = "1px solid var(--themeColor)";
        //   return false;
        // }
      }
      if (that.id.length != 18) {
        //校验身份证
        that.$refs.id.placeholder = "身份证号错误";
        that.$refs.id.style.border = "1px solid var(--themeColor)";
        return false;
      } else if (that.id.length == 18) {
        that.$refs.id.style.border = "";
      }
      if (that.ServeName1.length == 0) {
        if (that.ServeName == "") {
          //校验服务对象姓名
          that.$refs.ServeName.placeholder = "不能为空";
          that.$refs.ServeName.style.border = "1px solid var(--themeColor)";
          return false;
        } else if (that.ServeName != "") {
          that.$refs.ServeName.style.border = "";
          that.setContractInfo.ServeName = that.ServeName;
        }
      } else if (that.ServeName1.length != 0 && this.isClick == true) {
        that.setContractInfo.ServeName = that.ServeName1[0].name;
      }
      if (that.ServeCity == "") {
        this.$message.error("请选择服务对象所在城市!");
        return false;
      }
      if (that.ServeAddress == "") {
        //校验服务对象住址
        that.$refs.ServeAddress.placeholder = "不能为空";
        that.$refs.ServeAddress.style.border = "1px solid var(--themeColor)";
        return false;
      } else if (that.ServeAddress != "") {
        that.$refs.ServeAddress.style.border = "";
      }

      this.setContractInfo.idCard = this.id;
      this.setContractInfo.ServeType = this.ServeType;
      this.setContractInfo.name = this.name;
      this.setContractInfo.mobile = this.number;
      this.setContractInfo.detailAddress = this.cityValue + this.ServeAddress;
      console.log(this.setContractInfo.detailAddress);

      that.$store.commit("setContract", this.setContractInfo);
      this.$router.push("/chooseServe");
    },
  },
};

let timeout;

function fetch(valueFind, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  function fake() {
    if (valueFind == "") {
      return false;
    } else {
      getNurseFind({ key: valueFind, volunteer: false }).then((res) => {
        var result = res.data.result;
        if (res.data.success) {
          const data = [];
          result.forEach((r) => {
            data.push({
              value: r.id,
              text: r.name,
              phone: r.mobile,
              idCard: r.idCard,
            });
          });
          callback(data);
        }
      });
    }
  }

  timeout = setTimeout(fake, 500);
}
</script>

<style scoped>
.big_box {
  position: relative;
}

.right_btn {
  padding: 18px 0;
  font-size: 20px;
  /* float: right; */
  height: 100%;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.btn2 {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}
/* -------------------------------------------------------------------------- */

.smallBox {
  width: 1520px;
  height: 606px;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.27);
  border-radius: 12px;
  padding: 40px 46px;
  display: flex;
  justify-content: space-between;
}
.line {
  height: 256px;
  border-left: 1px solid #e2e2e2;
  margin-top: 135px;
}
.left_top img {
  width: 24px;
  height: 24px;
  margin-right: 18px;
  vertical-align: sub;
}
.left_top span {
  font-size: 22px;
}
.left_top {
  padding-bottom: 32px;
  margin-bottom: 62px;
  width: 687px;
  border-bottom: 1px solid #e2e2e2;
}
.left_bot {
  margin-left: 50px;
  width: 553px;
  font-size: 20px;
}
.left_bot > div {
  display: flex;
  justify-content: space-between;
}
input::placeholder {
  font-size: 20px;
  color: #cacaca;
}

:deep(.ant-radio-checked .ant-radio-inner) {
  border-color: var(--themeColor);
}
:deep(.ant-radio-inner:hover, .ant-radio:hover, .ant-radio-wrapper-checked:hover) {
  border-color: var(--themeColor);
}
:deep(.ant-radio-inner::after) {
  background-color: var(--themeColor);
}
:deep(.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover
    .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner) {
  border-color: var(--themeColor);
}
:deep(.ant-radio-wrapper) {
  /* margin-top: -0px; */
  margin-right: 50px;
}

:deep(.ant-radio-wrapper span:nth-child(2)) {
  font-size: 20px;
}
:deep(.ant-radio) {
  vertical-align: text-top;
}
:deep(.ant-select-selection-search-input) {
  height: 48px !important;
  /* line-height: 48px !important; */
}
:deep(.ant-select-selection-placeholder) {
  height: 48px !important;
  line-height: 48px !important;
  font-size: 20px;
}

:deep(.ant-select-selection-search::before) {
  position: absolute;
  content: "";
  height: 0px;
  width: 0px;
  border: 8px solid;
  border-color: #ccc transparent transparent transparent;
  right: -8px;
  top: 18px;
}
:deep(.name .ant-select-selection-search::before) {
  right: 6px;
  top: 18px;
}
.userName {
  width: 372px;
  height: 48px;
  border-radius: 8px;
  line-height: 48px;
  padding-left: 20px;
  border: 1px solid #ccc;
}
input:focus {
  border: none;
  box-shadow: 0px 0px 3px var(--themeColor);
  border: 1px solid var(--themeColor);
}
input:hover {
  border: 1px solid var(--themeColor);
}
.text_box {
  height: 48px;
  line-height: 48px;
  margin-top: 30px;
}

.city_bg {
  height: 47px;
  width: 47px;
  background: rgba(204, 204, 204, 0.445);
  margin-right: -698px;
  overflow: hidden;
  border-radius: 8px;
  text-align: center;
}
.city_bg img {
  width: 14px;
  height: 14px;
}

.nextPage {
  width: 257px;
  height: 64px;
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
  border-radius: 10px;
  position: absolute;
  bottom: 192px;
  left: 740px;
  font-size: 20px;
}

:deep(.ant-select) {
  width: 372px !important;
  height: 48px !important;
}
:deep(.ant-select-selector) {
  width: 372px !important;
  height: 48px !important;
  border-radius: 8px !important;
  border: 1px solid #ccc !important;
}
:deep(.ant-select-selector::before) {
  content: "";
  height: 48px;
  width: 48px;
  background-color: rgba(204, 204, 204, 0.527);
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: -1px;
}
/* :deep(.ant-select-selection-item::before) {
  position: absolute;
  content: "";
  height: 0px;
  width: 0px;
  border: 8px solid;
  border-color: #ccc transparent transparent transparent;
  right: 5px;
  top: 18px;
} */
:deep(.ant-select-selector:hover) {
  border: 1px solid var(--themeColor) !important;
}
:deep(.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  border: 1px solid var(--themeColor) !important;
  outline: 0;
  box-shadow: 0px 0px 4px var(--themeColor);
}
:deep(.ant-select-selection-item) {
  height: 48px !important;
  line-height: 46px !important;
  font-size: 20px !important;
  margin-left: 8px;
}
:deep(.ant-select-arrow) {
  display: none;
}

/* ------------------------------------------------------------- */
:deep(.ant-cascader-picker) {
  width: 372px;
  line-height: 50px;
}
:deep(.ant-cascader-picker:hover input) {
  border: 1px solid var(--themeColor);
}
:deep(.ant-cascader-picker::after) {
  content: "";
  display: inline-block;
  height: 48px;
  width: 48px;
  background-color: rgba(204, 204, 204, 0.575);
  position: absolute;
  right: 0px;
  border-radius: 8px;
  margin-top: -2px;
}
:deep(.ant-cascader-picker::before) {
  position: absolute;
  content: "";
  height: 0px;
  width: 0px;
  border: 8px solid;
  border-color: #ccc transparent transparent transparent;
  right: 16px;
  top: 18px;
}
:deep(.ant-cascader-picker input) {
  height: 48px;
  margin-top: -8px;
  border-radius: 8px;
  font-size: 20px;
}
:deep(.ant-cascader-picker:focus .ant-cascader-input) {
  outline: 0;
  box-shadow: 0px 0px 4px var(--themeColor);
}

:deep(.ant-input::placeholder) {
  font-size: 20px;
  height: 48px;
  padding: 8px;
  color: #666;
}
:deep(.ant-cascader-picker-label) {
  padding: 0px 48px 0px 21px;
  font-size: 20px;
  margin-top: -12px;
}
:deep(.ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-color: var(--themeColor);
}
:deep(.ant-cascader-picker:focus .ant-cascader-input) {
  border-color: var(--themeColor);
}
:deep(.ant-input:focus) {
  border: 1px solid var(--themeColor);
  box-shadow: none;
}
:deep(svg) {
  display: none;
}
:deep(.anticon) {
  display: none;
}
</style>
